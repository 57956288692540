// src/pages/Tops.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import './Tops.css';
import TopTable from '../components/TopTable';
import AnimatedContainer from '../components/AnimatedContainer';
import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';

const Tops = () => {
  const { t, i18n } = useTranslation();
  const [top10, setTop10] = useState([]);
  const [popular, setPopular] = useState([]);
  const [urgent, setUrgent] = useState([]);
  const [top100, setTop100] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const location = useLocation();

  // Extraire le paramètre 'length' de l'URL
  const queryParams = new URLSearchParams(location.search);
  const lengthParam = queryParams.get('length');

  // Définir le bouton actif en fonction du paramètre 'length' lors de l'initialisation
  const initialActiveButton = lengthParam ? `length-${lengthParam}` : 'length-4';
  const [activeButton, setActiveButton] = useState(initialActiveButton); // Initialisation basée sur lengthParam

  useEffect(() => {
    const fetchTops = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = { language: i18n.language };
        if (activeButton.startsWith('length-')) {
          const length = activeButton.split('-')[1];
          params.length = length;
        }
        console.log(`Fetching tops with params:`, params);
        const response = await axios.get('/api/tops', { params });
        setTop10(response.data.top10);
        setPopular(response.data.popular);
        setUrgent(response.data.urgent);
        setTop100(response.data.top100);
        console.log(`Data received:`, response.data);
      } catch (err) {
        console.error('Erreur lors de la récupération des tops:', err);
        setError(t('Erreur lors de la récupération des tops'));
      } finally {
        setLoading(false);
      }
    };

    fetchTops();
  }, [i18n.language, activeButton, t]);

  const renderTop = () => {
    if (activeButton.startsWith('length-')) {
      const length = activeButton.split('-')[1];
      return (
        <>
          <Typography variant="h4" gutterBottom data-aos="fade-up">
            {t('Top10')} ({length} {t('lettres')})
          </Typography>
          {top10.length > 0 ? (
            <TopTable data={top10} type="top10" />
          ) : (
            <Typography variant="body1">{t('Aucun résultat')}</Typography>
          )}
        </>
      );
    } else {
      switch (activeButton) {
        case 'popular':
          return (
            <>
              <Typography variant="h4" gutterBottom data-aos="fade-up">
                {t('Populaires')}
              </Typography>
              {popular.length > 0 ? (
                <TopTable data={popular} type="popular" />
              ) : (
                <Typography variant="body1">{t('Aucun résultat')}</Typography>
              )}
            </>
          );
        case 'urgent':
          return (
            <>
              <Typography variant="h4" gutterBottom data-aos="fade-up">
                {t('Pressés')}
              </Typography>
              {urgent.length > 0 ? (
                <TopTable data={urgent} type="urgent" />
              ) : (
                <Typography variant="body1">{t('Aucun résultat')}</Typography>
              )}
            </>
          );
        case 'top100':
          return (
            <>
              <Typography variant="h4" gutterBottom data-aos="fade-up">
                {t('Top100')}
              </Typography>
              {top100.length > 0 ? (
                <TopTable data={top100} type="top100" />
              ) : (
                <Typography variant="body1">{t('Aucun résultat')}</Typography>
              )}
            </>
          );
        default:
          return null;
      }
    }
  };

  // Générer les boutons de longueur de 4 à 15 lettres
  const renderLengthButtons = () => {
    const lengths = Array.from({ length: 12 }, (_, i) => i + 4); // 4 à 15
    return (
      <div className="length-buttons">
        {lengths.map((len) => (
          <motion.button
            key={len}
            className={activeButton === `length-${len}` ? 'active' : ''}
            onClick={() => {
              setActiveButton(`length-${len}`);
              console.log(`Active button changed to length-${len}`);
            }}
            whileHover={{ scale: 1.05, boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)' }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            {len}
          </motion.button>
        ))}
      </div>
    );
  };

  // Générer les boutons de catégories
  const renderCategoryButtons = () => {
    const categories = [
      { id: 'popular', label: t('Populaires') },
      { id: 'urgent', label: t('Pressés') },
      { id: 'top100', label: t('Top100') },
    ];

    return (
      <div className="category-buttons">
        {categories.map((category) => (
          <motion.button
            key={category.id}
            className={activeButton === category.id ? 'active' : ''}
            onClick={() => {
              setActiveButton(category.id);
              console.log(`Active button changed to ${category.id}`);
            }}
            whileHover={{ scale: 1.05, boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)' }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            {category.label}
          </motion.button>
        ))}
      </div>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="tops-container">
      <Typography variant="h2" gutterBottom>
        {t('Classements')}
      </Typography>
      {/* Boutons de Longueur */}
      {renderLengthButtons()}
      {/* Boutons de Catégorie */}
      {renderCategoryButtons()}
      {/* Contenu des Tops */}
      <div className="tops-content">
        {renderTop()}
      </div>
    </div>
  );
};

export default Tops;
