// src/i18n/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Initialiser i18n
i18n
  .use(HttpBackend) // Charge les fichiers de traduction via HTTP
  .use(LanguageDetector) // Détecte la langue préférée de l'utilisateur
  .use(initReactI18next) // Passe i18n à react-i18next
  .init({
    fallbackLng: 'fr',
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    },
    detection: {
      // Options de détection de langue
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false // React s'occupe déjà de l'échappement
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
