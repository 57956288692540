import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

// Styled Components
const Canvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* En arrière-plan */
  background: #000;
`;

const MatrixRain = () => {
  const canvasRef = useRef(null);
  const columns = 256;
  let drops = [];

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Définir la taille du canvas
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Caractères utilisés dans l'effet Matrix
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const fontSize = 16;
    const columnsCount = Math.floor(canvas.width / fontSize);

    // Initialiser les gouttes
    drops = Array(columnsCount).fill(0);

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#0F0'; // Vert Matrix
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < drops.length; i++) {
        const text = characters.charAt(Math.floor(Math.random() * characters.length));
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        // Reset si la goutte sort de l'écran
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i]++;
      }
    };

    const interval = setInterval(draw, 33); // ~30fps

    // Redimensionner le canvas lors du redimensionnement de la fenêtre
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <Canvas ref={canvasRef} />;
};

export default MatrixRain;