import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Constants
const PARTICLE_COUNT = 50;
const PARTICLE_GENERATION_DURATION = 2000; // en millisecondes
const MIN_ANIMATION_DURATION = 2; // en secondes
const MAX_ANIMATION_DURATION = 4; // en secondes
const EMISSION_ZONE_RADIUS = 50; // Rayon de la zone d'émission en pixels

const MotionLoaderOverlay = motion.div;
const MotionNumberParticle = motion.div;

const LoaderOverlay = styled(MotionLoaderOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
`;

const NumberParticleStyled = styled(MotionNumberParticle)`
  position: absolute;
  font-size: 1rem;
  color: #00ff00;
  user-select: none;
  transform-origin: center;
`;

const particleVariants = {
  initial: (custom) => ({
    opacity: 0,
    scale: 0.01,
    x: custom.x_start,
    y: custom.y_start,
    rotate: custom.rotate_start,
  }),
  animate: (custom) => ({
    opacity: [0, 1, 1, 0],
    scale: [0.01, 3, 4, 0.01],
    x: [custom.x_start, custom.x_end],
    y: [custom.y_start, custom.y_end],
    rotate: [custom.rotate_start, custom.rotate_end],
    transition: {
      duration: custom.duration,
      delay: custom.delay,
      ease: 'easeInOut',
      times: [0, 0.3, 0.6, 1],
    },
  }),
};

const loaderVariants = {
  initial: { opacity: 1 },
  exit: { opacity: 0 },
};

const PageLoader = ({ 
  onComplete,
  fadeOutDuration = 1, // Durée du fadeout en secondes
  minParticleSpeed = 0.25, // Vitesse minimale des particules (facteur)
  maxParticleSpeed = 1, // Vitesse maximale des particules (facteur)
  overlayFadeOutStart = 0.2 // Pourcentage de l'animation totale avant de commencer le fadeout de l'overlay
}) => {
  const [particles, setParticles] = useState([]);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const generateParticles = () => {
      const newParticles = [];
      const screenDiagonal = Math.sqrt(window.innerWidth ** 2 + window.innerHeight ** 2);

      for (let i = 0; i < PARTICLE_COUNT; i++) {
        const emissionAngle = Math.random() * 2 * Math.PI;
        const emissionRadius = Math.random() * EMISSION_ZONE_RADIUS;
        const x_start = Math.cos(emissionAngle) * emissionRadius;
        const y_start = Math.sin(emissionAngle) * emissionRadius;

        const trajectoryAngle = Math.random() * 2 * Math.PI;
        const distance = screenDiagonal;
        const x_end = Math.cos(trajectoryAngle) * distance;
        const y_end = Math.sin(trajectoryAngle) * distance;

        const number = Math.floor(Math.random() * 10);
        const speedFactor = minParticleSpeed + Math.random() * (maxParticleSpeed - minParticleSpeed);
        const duration = (Math.random() * (MAX_ANIMATION_DURATION - MIN_ANIMATION_DURATION) + MIN_ANIMATION_DURATION) / speedFactor;
        const delay = Math.random() * (PARTICLE_GENERATION_DURATION / 1000);

        const rotate_start = Math.random() * 180 - 90;
        const rotate_end = rotate_start + (Math.random() * 180 - 90);

        newParticles.push({
          id: i,
          x_start,
          y_start,
          x_end,
          y_end,
          number,
          duration,
          delay,
          rotate_start,
          rotate_end,
        });
      }
      setParticles(newParticles);
    };

    generateParticles();

    const maxParticleDuration = PARTICLE_GENERATION_DURATION / 1000 + MAX_ANIMATION_DURATION / minParticleSpeed;
    const overlayFadeOutStartTime = maxParticleDuration * overlayFadeOutStart;

    const timer = setTimeout(() => {
      setIsComplete(true);
    }, overlayFadeOutStartTime * 1000);

    return () => clearTimeout(timer);
  }, [minParticleSpeed, maxParticleSpeed, overlayFadeOutStart]);

  useEffect(() => {
    if (isComplete) {
      const fadeOutTimer = setTimeout(() => {
        if (onComplete) onComplete();
      }, fadeOutDuration * 1000);
      return () => clearTimeout(fadeOutTimer);
    }
  }, [isComplete, onComplete, fadeOutDuration]);

  return (
    <AnimatePresence>
      {!isComplete && (
        <LoaderOverlay
          variants={loaderVariants}
          initial="initial"
          animate="initial"
          exit="exit"
          transition={{ duration: fadeOutDuration }}
        >
          {particles.map((particle) => (
            <NumberParticleStyled
              key={particle.id}
              custom={particle}
              variants={particleVariants}
              initial="initial"
              animate="animate"
            >
              {particle.number}
            </NumberParticleStyled>
          ))}
        </LoaderOverlay>
      )}
    </AnimatePresence>
  );
};

PageLoader.propTypes = {
  onComplete: PropTypes.func,
  fadeOutDuration: PropTypes.number,
  minParticleSpeed: PropTypes.number,
  maxParticleSpeed: PropTypes.number,
  overlayFadeOutStart: PropTypes.number,
};

export default PageLoader;