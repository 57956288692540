// src/components/OccurrenceBar.js

import React from 'react';
import './OccurrenceBar.css'; // Créez ce fichier pour les styles
import { motion } from 'framer-motion';

const OccurrenceBar = ({ occurrences }) => {
  const maxOccurrence = 1000000000;
  const maxPoints = 200; // Limiter à un maximum de 500 points

  // Si le nombre de points dépasse maxPoints, regrouper
  const groupedOccurrences = [];
  if (occurrences.length > maxPoints) {
    const groupSize = Math.ceil(occurrences.length / maxPoints);
    for (let i = 0; i < occurrences.length; i += groupSize) {
      const group = occurrences.slice(i, i + groupSize);
      const avgOccurrence = group.reduce((sum, occ) => sum + occ, 0) / group.length;
      groupedOccurrences.push(avgOccurrence);
    }
  } else {
    groupedOccurrences.push(...occurrences);
  }

  return (
    <div className="occurrence-bar-container">
      <div className="occurrence-bar">
        {groupedOccurrences.map((occurrence, index) => {
          const positionPercent = Math.min((occurrence / maxOccurrence) * 100, 100);
          return (
            <motion.div
              key={index}
              className="occurrence-point"
              style={{ left: `${positionPercent}%` }}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.005 }} // Transition rapide
            />
          );
        })}
      </div>
      <div className="occurrence-labels">
        <span>0</span>
        <span>1,000,000,000</span>
      </div>
    </div>
  );
};


export default OccurrenceBar;