// src/pages/PrivacyPolicy.js

import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div class="content-container">
      <h2>{t('politique_confidentialite')}</h2>
      
      <h3>{t('pp_title_1')}</h3>
      <p>{t('pp_content_1')}</p>
      <h3>{t('pp_title_2')}</h3>
      <p>{t('pp_content_2')}</p>
      <h3>{t('pp_title_3')}</h3>
      <p>{t('pp_content_3')}</p>
      <h3>{t('pp_title_4')}</h3>
      <p>{t('pp_content_4')}</p>
      <h3>{t('pp_title_5')}</h3>
      <p>{t('pp_content_5')}</p>

    </div>
  );
};

export default PrivacyPolicy;
