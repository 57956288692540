// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import Search from './pages/Search';
import Tops from './pages/Tops';
import Battle from './pages/Battle';
import About from './pages/About';
import LegalNotices from './pages/LegalNotices';
import PrivacyPolicy from './pages/PrivacyPolicy';
import MatrixRain from './components/MatrixRain'; // Import MatrixRain
import PageLoader from './components/PageLoader'; // Import PageLoader

const pageVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: -20,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Search />
            </motion.div>
          }
        />
        <Route
          path="/search"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Search />
            </motion.div>
          }
        />
        <Route
          path="/battle"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Battle />
            </motion.div>
          }
        />
        <Route
          path="/tops"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Tops />
            </motion.div>
          }
        />
        <Route
          path="/about"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <About />
            </motion.div>
          }
        />
        <Route
          path="/legal-notices"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <LegalNotices />
            </motion.div>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <PrivacyPolicy />
            </motion.div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Simulate a loading period of 5 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      {/* MatrixRain is displayed as a background effect */}
      <MatrixRain />
      
      {/* PageLoader for loading animation */}
      {loading ? (
        <PageLoader onComplete={() => setLoading(false)} />
      ) : (
        <>
          <Header />
          <main className="content" style={{ paddingTop: '80px', paddingBottom: '80px', minHeight: '100vh', boxSizing: 'border-box' }}>
            <AnimatedRoutes />
          </main>
          <Footer />
        </>
      )}
    </Router>
  );
};

export default App;
