// src/pages/Search.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Share from '../components/Share';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Typography from '@mui/material/Typography';
import LoadingSpinner from '../components/LoadingSpinner';
import OccurrenceBar from '../components/OccurrenceBar'; // Import du nouveau composant
import './Search.css'; // Assurez-vous d'avoir ce fichier pour les styles

const Search = () => {
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useState('');
  const [numericSequence, setNumericSequence] = useState('');
  const [resultCount, setResultCount] = useState(null);
  const [positions, setPositions] = useState([]); // Nouveau state pour les positions
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);
  const [isTop10, setIsTop10] = useState(false); // Nouveau state pour Top 10
  const navigate = useNavigate(); // Hook pour la navigation

  // Fonction pour convertir lettres en chiffres (optionnel pour affichage)
  const convertToNumeric = (text) => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    return text
      .normalize('NFD') // Normaliser pour séparer les accents
      .replace(/[\u0300-\u036f]/g, '') // Supprimer les diacritiques
      .toLowerCase()
      .replace(/[^a-z]/g, '') // Supprimer les caractères non a-z
      .split('')
      .map(char => {
        const index = alphabet.indexOf(char);
        return index !== -1 ? index + 1 : 0;
      })
      .join('');
  };

  // Mettre à jour la séquence numérique en temps réel (optionnel pour affichage)
  useEffect(() => {
    const numeric = convertToNumeric(query);
    setNumericSequence(numeric);
  }, [query]);

  // Validation des contraintes de saisie
  const isValid = query.trim().length >= 4 && query.trim().length <= 15 && /^[A-Za-zÀ-ÿ\s]+$/.test(query);

  // Gestion de la soumission de la recherche
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) return;
    setIsSubmitting(true);

    console.log(`Submitting search for word: ${query.trim()}`);

    try {
      const response = await axios.post('/api/search', { // Assurez-vous que cette URL est correcte
        word: query.trim(),
        language: i18n.language
      });
      setResultCount(response.data.occurrences);
      setPositions(response.data.positions);
      setShareVisible(true); // Afficher la section de partage si nécessaire

      // Vérifier si le mot est dans le Top 10
      const length = query.trim().length;
      console.log(`Fetching tops for language: ${i18n.language}, length: ${length}`);
      const topsResponse = await axios.get('/api/tops', {
        params: {
          language: i18n.language,
          length: length
        }
      });
      const isInTop10 = topsResponse.data.top10.some(item => item.word.toLowerCase() === query.trim().toLowerCase());
      setIsTop10(isInTop10);
      console.log(`Is "${query.trim()}" in Top10? ${isInTop10}`);
    } catch (error) {
      console.error('Erreur lors de la recherche:', error);
      alert(t('Erreur lors de la recherche')); // Ajouter une traduction pour ce message
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewRanking = () => {
    const length = query.trim().length;
    console.log(`Navigating to /tops?length=${length}`);
    navigate(`/tops?length=${length}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="search-container"
    >
      <Typography variant="h5" className="search-title">
  Transformez un mot ou un prénom en chiffres et découvrez s’il se cache dans le premier milliard de décimales de Pi !
</Typography>
      <Typography variant="h4" gutterBottom>
        {t('search')}
      </Typography>
      <form onSubmit={handleSubmit} className="search-form">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('search')}
          className="search-input"
        />
        <motion.button
          type="submit"
          disabled={!isValid || isSubmitting}
          className="search-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          {isSubmitting ? t('Recherche en cours') : t('search')}
        </motion.button>
      </form>

      {/* Afficher le LoadingSpinner pendant la soumission */}
      {isSubmitting && <LoadingSpinner />}

      {/* Afficher les résultats lorsque la soumission n'est pas en cours */}
      {!isSubmitting && (
        <>
          {numericSequence && (
            <Typography variant="body1" className="numeric-sequence">
              {t('sequence_numerique')} : {numericSequence}
            </Typography>
          )}
          {resultCount !== null && (
            <AnimatePresence>
              <motion.div
                className="result-count"
                initial={{ scale: 0 }}
                animate={{ scale: 1.2 }}
                exit={{ scale: 0 }}
                transition={{ duration: 0.5 }}
              >
                {t('results')}: {resultCount}
              </motion.div>
            </AnimatePresence>
          )}
          
          {/* Afficher la barre des occurrences */}
          {positions.length > 0 && (
            <OccurrenceBar occurrences={positions} />
          )}

          {isTop10 && (
            <Typography variant="body1" className="top10-indicator">
              {t('top10_message')}
            </Typography>
          )}
          {resultCount !== null && (
            <motion.button
              onClick={handleViewRanking}
              className="view-ranking-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              {t('voir_classement')}
            </motion.button>
          )}
          {shareVisible && resultCount > 0 && (
            <Share />
          )}
        </>
      )}
    </motion.div>
  );
};

export default Search;