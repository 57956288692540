// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './i18n/i18n'; // Importer la configuration i18n


AOS.init({
  duration: 800,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
