// src/pages/Battle.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import Typography from '@mui/material/Typography';
import LoadingSpinner from '../components/LoadingSpinner';
import './Battle.css'; // Nouveau fichier CSS pour les styles de Battle

const Battle = () => {
  const { t, i18n } = useTranslation();
  const [query1, setQuery1] = useState('');
  const [query2, setQuery2] = useState('');
  const [result1, setResult1] = useState(null);
  const [result2, setResult2] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [winner, setWinner] = useState(null);

  const isValid = (query) => query.trim().length >= 4 && query.trim().length <= 15;

  // Gestion de la soumission des deux mots
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid(query1) || !isValid(query2)) return;
    setIsSubmitting(true);
    setWinner(null); // Réinitialiser le gagnant

    try {
      const [response1, response2] = await Promise.all([
        axios.post('/api/search', { word: query1.trim(), language: i18n.language }),
        axios.post('/api/search', { word: query2.trim(), language: i18n.language }),
      ]);

      const result1Occurrences = response1.data.occurrences;
      const result2Occurrences = response2.data.occurrences;

      setResult1(result1Occurrences);
      setResult2(result2Occurrences);

      // Déterminer le gagnant
      if (result1Occurrences > result2Occurrences) {
        setWinner(query1);
      } else if (result2Occurrences > result1Occurrences) {
        setWinner(query2);
      } else {
        setWinner('draw');
      }
    } catch (error) {
      console.error('Erreur lors de la recherche:', error);
      alert(t('Erreur lors de la recherche'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="battle-container content-container"
    >
      <Typography variant="h5" className="battle-title">
        {t('Duel entre deux mots : Quel mot est le plus fréquent dans Pi ?')}
      </Typography>

      <form onSubmit={handleSubmit} className="battle-form">
        <div className="input-container">
          <input
            type="text"
            value={query1}
            onChange={(e) => setQuery1(e.target.value)}
            placeholder={t('Entrez le premier mot')}
            className="battle-input"
          />
          <span className="vs-text">VS</span>
          <input
            type="text"
            value={query2}
            onChange={(e) => setQuery2(e.target.value)}
            placeholder={t('Entrez le second mot')}
            className="battle-input"
          />
        </div>

        <motion.button
          type="submit"
          disabled={!isValid(query1) || !isValid(query2) || isSubmitting}
          className="battle-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          {isSubmitting ? t('Recherche en cours') : t('Lancer le combat')}
        </motion.button>
      </form>

      {isSubmitting && <LoadingSpinner />}

      {!isSubmitting && result1 !== null && result2 !== null && (
        <>
          <AnimatePresence>
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1.2, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="results-container"
            >
              <div className="result">
                <Typography variant="h6">{query1}</Typography>
                <Typography variant="body1">{t('Occurrences')}: {result1}</Typography>
              </div>
              <div className="result">
                <Typography variant="h6">{query2}</Typography>
                <Typography variant="body1">{t('Occurrences')}: {result2}</Typography>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Affichage du gagnant */}
          <AnimatePresence>
            {winner && (
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1.5, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.7 }}
                className={`winner ${winner === 'draw' ? 'draw' : ''}`}
              >
                {winner === 'draw'
                  ? t('C\'est un match nul!')
                  : `${winner} ${t('est le gagnant !')}`}
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </motion.div>
  );
};

export default Battle;