// src/components/Share.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaTwitter } from 'react-icons/fa'; // Utilisation d'icônes
import './Share.css'; // Créez ce fichier pour les styles

const Share = React.memo(() => {
  const { t } = useTranslation();

  const handleShare = (platform) => {
    const url = window.location.href;
    let shareUrl = '';

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, '_blank');
  };

  return (
    <div className="share-section">
      <h3>{t('share')}</h3>
      <div className="share-icons">
        <FaFacebook onClick={() => handleShare('facebook')} className="share-icon" />
        <FaTwitter onClick={() => handleShare('twitter')} className="share-icon" />
        {/* Ajoutez d'autres icônes de réseaux sociaux si nécessaire */}
      </div>
    </div>
  );
});

export default Share;
