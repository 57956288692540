// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import ButtonsMenu from './ButtonsMenu';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [languages, setLanguages] = useState({});
  const [menuOpen, setMenuOpen] = useState(false); // État pour le menu hamburger
  const location = useLocation();

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get('/locales/languages.json');
        setLanguages(response.data);

        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && response.data[savedLanguage]) {
          i18n.changeLanguage(savedLanguage);
        } else {
          const detectedLanguage = i18n.language;
          if (response.data[detectedLanguage]) {
            i18n.changeLanguage(detectedLanguage);
          } else {
            i18n.changeLanguage('fr');
          }
        }
      } catch (error) {
        console.error('Erreur lors du chargement des langues:', error);
      }
    };

    fetchLanguages();
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Inverse l'état du menu
  };

  return (
    <header style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
      <nav className="navbar">
        <div className="logo-container">
          <Link to="/">
            <img src="/images/piwords-logo.png" alt="PiWords Logo" className="logo" />
          </Link>
        </div>
        <button className="hamburger" onClick={toggleMenu}>
          &#9776;
        </button>
        <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <li>
            <Link to="/search" className={location.pathname === '/search' ? 'active' : ''}>
              {t('search')}
            </Link>
          </li>
          <li>
            <Link to="/battle" className={location.pathname === '/battle' ? 'active' : ''}>
              {t('battle')}
            </Link>
          </li>
          <li>
            <Link to="/tops" className={location.pathname === '/tops' ? 'active' : ''}>
              {t('top10')}
            </Link>
          </li>
          <li>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>
              {t('about')}
            </Link>
          </li>
          <li className="language-selector">
            <label htmlFor="language-select">{t('language')}:</label>
            <select
              id="language-select"
              onChange={(e) => changeLanguage(e.target.value)}
              value={i18n.language}
            >
              {Object.entries(languages).map(([code, label]) => (
                <option key={code} value={code}>{label}</option>
              ))}
            </select>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;