// src/components/LoadingSpinner.js

import React from 'react';
import './LoadingSpinner.css'; // Créez ce fichier pour les styles

const LoadingSpinner = () => (
  <div className="spinner-container">
    <div className="loading-spinner"></div>
  </div>
);

export default LoadingSpinner;
