// src/pages/About.js

import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t('about')}</h2>
      <p>{t('about_description')}</p>
      <h3>{t('mission')}</h3>
      <p>{t('mission_description')}</p>
      <h3>{t('features')}</h3>
      <ul>
        <li>{t('feature_search')}</li>
        <li>{t('feature_tops')}</li>
        <li>{t('feature_multilanguage')}</li>
        <li>{t('feature_share')}</li>
      </ul>
      <h3>{t('team')}</h3>
      <p>{t('team_description')}</p>
    </div>
  );
};

export default About;
