// src/components/Footer.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa'; // Utilisation d'icônes
import { Link, useLocation } from 'react-router-dom';
import './Footer.css'; // Créez ce fichier pour les styles

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <ul className="footer-links">
        <li>
          <Link to="/legal-notices" className={location.pathname === '/legal-notices' ? 'active' : ''}>
            {t('mentions_legales')}
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy" className={location.pathname === '/privacy-policy' ? 'active' : ''}>
            {t('politique_confidentialite')}
          </Link>
        </li>
        <li>
          <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>
            {t('about')}
          </Link>
        </li>
      </ul>
      <div id="ads-zone">
        {/* Zone pour afficher des publicités externes */}
        <p>Publicité</p>
      </div>
      <div className="social-icons">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebook />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <FaTwitter />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <FaLinkedin />
        </a>
      </div>
      <p>&copy; {new Date().getFullYear()} PiWords. {t('all_rights_reserved')}</p>
    </footer>
  );
};

export default Footer;