// src/components/TopTable.js

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
import { motion } from 'framer-motion';

const MotionTableRow = motion(TableRow);

const TopTable = ({ data, type }) => {
  const getHeader = () => {
    switch (type) {
      case 'top10':
        return ['Position', 'Mot', 'Occurrences'];
      case 'popular':
        return ['Position', 'Mot', 'Recherches'];
      case 'urgent':
        return ['Position', 'Mot', 'Première Occurrence'];
      case 'top100':
        return ['Position', 'Mot', 'Occurrences'];
      default:
        return ['Position', 'Mot', 'Valeur'];
    }
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            {getHeader().map((header, index) => (
              <TableCell key={index}>
                <Typography variant="h6" component="div">
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <MotionTableRow
              key={index}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.05 }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.word}</TableCell>
              <TableCell>
                {type === 'top10' && item.count}
                {type === 'popular' && item.searchCount}
                {type === 'urgent' && new Date(item.firstOccurrence).toLocaleDateString()}
                {type === 'top100' && item.count}
              </TableCell>
            </MotionTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopTable;
